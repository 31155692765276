import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutENG'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'


import "../components/layout.css"

export const AboutPageTemplateENG = ({ intro, gallery }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className="elooo alternatywka"><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                         <Features
                           gridItems={intro.blurbs}
                           key={intro.toString()}
                           href=""
                  />
                  <div className="descriptionTEXT">
                    <p className="descriptionTemat">Mission and vision</p>
                    <p className="descriptionMisjaiWizja">Our mission:</p>


                    <p className="descriptionCSS">Customer satisfaction won by solid job, economical management of resources, and modern production technologies application <br></br> <br></br>

                      Our objective is achieved by:</p><ul className="ListaKolo">
                      <li>continuous extension of machines,</li>
                      <li>extension of offered product range,</li>
                      <li>employment of highly skilled people.</li>
                    </ul>

                    <p className="descriptionMisjaiWizja">Our vision:</p>
                    <p className="descriptionCSS">We want our brand to be known and recognised in the home roof hardware market and to successively win trust abroad.<br></br> <br></br>

                      For this to happen Ekochron has the following strengths:</p>
                    <ul className="ListaKolo" >
                      <li>comprehensive service,</li>
                      <li>high quality products,</li>
                      <li>short lead times,</li>
                      <li>expertise and experience;</li>
                      <li>quick and flexible reactions to customer needs.</li>
                    </ul>
                    </div>
                       </div>
                       <div className="galeriaRIGHT galeriaRIGHTonas">

                         <div className="galeriaONAS">
                           <Features gridItems={gallery.blurbs} />
                         </div>
                       </div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           The last notes:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Read more
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

AboutPageTemplateENG.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const AboutPageENG = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplateENG
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

AboutPageENG.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPageENG

export const pageQuery = graphql`
         query AboutPageTemplateENG {
           markdownRemark(
             frontmatter: { templateKey: { eq: "about-pageENG" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
